<template>
    <div class="DfFlatUiCustomizationView ViewWrapper LwHaB">
        <clv-head-meta :title="[
            stateIsChanging === true ? $t('common_state_loading') + '...' : null,
            pageTitle,
            $t('Not Found')
        ]"></clv-head-meta>

        <!-- VIEW LOADER -->
        <element-loading :active="stateIsChanging" :is-full-view="true" :lock-parent="true"></element-loading>

        <!-- EMPTY STATE 2020-07-19 -->
        <simple-state-not-found :for-view="true" v-if="displayEmptyState">
            <div class="mt-2" slot="action">
                <router-link tag="a" :to="{ name: 'tools' }"><i class="fas fa-chevron-left"></i> {{ $t('Tools') }}</router-link>
            </div>
        </simple-state-not-found>

        <!-- MAIN -->
        <div class="d-md-flex flex-md-column LwHaB-Main Disable-md" v-if="displayMain">
            <df-flat-ui-customization-header></df-flat-ui-customization-header>
            <div class="flex-grow-1 flex-shrink-1 LwHaB-Main__Body WithMultipleColumns DisableMultipleColumns-sm">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="LwHaB-Main__Body__Column WithMarginTop disable-sm CustomScrollbar--Default bg-white border">
                                <div class="px-3 py-3">
                                    <div class="mb-3"
                                         v-for="(pgOption, pgOptionIndex) in pgOptions"
                                         :key="pgOption.rId">
                                        <p class="mb-1 small weight-6">{{ pgOption.label }}</p>
                                        <div v-for="(pdOption, pdOptionIndex) in pgOption.pdOptions"
                                             :key="pdOption.rId">
                                            <b-form-checkbox :id="pdOption.name"
                                                             :checked="pdOption.active"
                                                             :name="pdOption.name"
                                                             :value="true"
                                                             :unchecked-value="false"
                                                             :disabled="pdActivesLengthOne && pdOption.active"
                                                             @change="handleCheckboxChange($event, pgOptionIndex, pdOptionIndex)">
                                                {{ pdOption.label }}
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-8">
                            <div class="LwHaB-Main__Body__Column WithMarginTop disable-sm WithMarginBottom-sm CustomScrollbar--Default">
                                <draggable v-model="pdActivesDnD"
                                           handle=".dnd-handle"
                                           ghost-class="Draggable-Ghost--Default"
                                           :force-fallback="true">
                                    <div class="gk-card as-listing-item"
                                         v-for="pdActive in pdActivesDnD"
                                         :key="pdActive.rId">
                                        <div class="d-flex align-items-center justify-content-start">
                                            <p class="mb-0 text-muted cursor-grab mr-2 dnd-handle">
                                                <small class="text-muted">
                                                    <i class="fas fa-grip-lines"></i>
                                                </small>
                                            </p>
                                            <p class="mb-0 size-6 mr-2">{{ pdActive.label }}</p>
                                        </div>
                                    </div>
                                </draggable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import RouterUtils from '@/common/utils/router.utils';
import DfFlatUiCustomizationHeader from './DfFlatUiCustomizationHeader';

/**
 * SchemaDefinition Flat UI Customization view.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 1 August 2020
 * @lastModifiedAt 16 March 2021
 */
export default {
    name: 'DfFlatUiCustomizationView',
    components: {
        draggable,
        DfFlatUiCustomizationHeader
    },
    computed: {
        ...mapGetters('dfFlatUiCustomization', {
            pageTitle: 'pageTitle',

            pgOptions: 'pgOptions',
            pdActives: 'pdActives',

            pdActivesLengthOne: 'pdActivesLengthOne',

            stateIsChanging: 'stateIsChanging',
            displayEmptyState: 'displayEmptyState',
            displayMain: 'displayMain'
        }),
        pdActivesDnD: {
            get () {
                return this.pdActives;
            },
            set (value) {
                this.$store.dispatch('dfFlatUiCustomization/handlePdActivesOrderChange', value)
                    .then(() => void 0).catch(() => void 0); // @future add toast in case of error?
            }
        }
    },
    watch: {
        '$route': {
            handler: function (to, from) {
                const result = RouterUtils.resourceIdHelper(to, from);
                this.$store.commit('dfFlatUiCustomization/setDomainAndKeyById', result.toId);

                // On route change, initialize.
                if (result.mustInitialize) {
                    this.$store.dispatch('dfFlatUiCustomization/resetModule').then(() => {
                        this.$store.commit('dfFlatUiCustomization/setDomainAndKeyById', result.toId);
                        this.$store.dispatch('dfFlatUiCustomization/initializeModule').then(() => void 0).catch(() => void 0);
                    }).catch(() => void 0);
                }
            },
            immediate: true
        }
    },
    methods: {
        handleCheckboxChange ($event, pgOptionIndex, pdOptionIndex) {
            this.$store.dispatch('dfFlatUiCustomization/handlePdOptionActiveChange', {
                newActive: $event,
                pgOptionIndex: pgOptionIndex,
                pdOptionIndex: pdOptionIndex
            }).then(() => void 0).catch(() => void 0); // @future add toast in case of error?
        }
    }
};
</script>
