<template>
    <div class="DfFlatUiCustomizationHeader bg-white border-bottom">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="py-3 px-0">
                        <p class="mb-1 text-microcopy--70 cursor-pointer text-primary"
                           @click="goBack"
                           v-if="displayGoBack">
                            <i class="fas fa-chevron-left"></i> {{ $t('Back') }}
                        </p>
                        <p class="mb-0 weight-7 size-5 line-height-2">{{ pageTitle }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

/**
 * The view header.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 1 August 2020
 * @lastModifiedAt 16 March 2021
 */
export default {
    name: 'DfFlatUiCustomizationHeader',
    computed: {
        ...mapGetters('dfFlatUiCustomization', {
            // df-dynamic sub-module
            schemaDefinitionName: 'schemaDefinitionName',
            // index
            pageTitle: 'pageTitle'
        }),
        displayGoBack () {
            // noinspection JSUnresolvedVariable
            return typeof this.$route.query.backToPerson === 'string';
        }
    },
    methods: {
        goBack () {
            // noinspection JSUnresolvedVariable
            const backToPerson = this.$route.query.backToPerson;
            if (typeof backToPerson !== 'string') return;
            this.$router.push({
                name: 'person',
                params: {
                    id: backToPerson
                }
            });
        }
    }
};
</script>

<i18n>
{
    "en": {
        "Back": "Back"
    },
    "el": {
        "Back": "Επιστροφή"
    }
}
</i18n>
